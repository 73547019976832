import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import { conf, t } from '../../domain/services/configService';
import { stripTags } from '../../domain/utils/stringUtils';
import { Image } from '../Image';
import { SdkVideoPlayer } from '../SdkVideoPlayer';
import { Actionable } from '../responsive/atoms/Actionable';
import { facePaint } from '../../domain/theme/utils/facePaint';
import {
  ngartImg,
  ngartImgCntr,
  ngartImgCont,
  ngartImgContAuthor,
  ngartImgContCopy,
  ngartImgContStrong,
  ngartImgPlayButton,
  ngartImgSize,
  ngartImgVideo
} from '../Styles/articleImage';
import { ParagraphWrapper } from '../Paragraph/ParagraphWrapper';
import {
  getContentPublishDate,
  getHubPage,
  getPrimaryTaxonomySlug
} from '../../domain/utils/contentUtils';
import { dateService } from '../../domain/services/dateService';
import { VideoSchema } from '../responsive/atoms/VideoSchema';
import { getVideoSchemaDataFromVideoEntity } from '../../domain/services/schemaService/types/videoObjectType';
import { imageResizerService } from '../../domain/services/imageResizerService';
import { breakpointService } from '../../domain/services/breakpointService';

const getContentImageEntity = content => {
  if (content.image?.entity?.mediaImage?.url) {
    return content.image.entity;
  }
  if (content.promoImage?.[0]?.entity?.mediaImage?.url) {
    return content.promoImage[0].entity;
  }
  if (content.videoRef?.entity?.image?.entity?.mediaImage?.url) {
    return content.videoRef.entity.image.entity;
  }
  return {
    mediaImage: {
      height: 455,
      title: content.title,
      url: `/files/images/logo.16x9.png`,
      width: 256
    }
  };
};

const fontColor = '#000';
const styles = {
  videoWrapper: css`
    width: 100%;
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
  `,
  image: css`
    position: relative;
    z-index: 0;
  `,
  video: css`
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  `,
  contentVisibility: css`
    content-visibility: auto;
    ${facePaint({
      'contain-intrinsic-size': ['0 476px', '0 587px', '0 488px', '0 520px']
    })};
  `,
  videoCredit: css`
    color: ${fontColor};
    margin-top: 10px;
  `
};

const ArticleVideoInner = ({ data, content, isParagraph, isAboveTheFold }) => {
  const val = isParagraph
    ? {
        heading: data.video.entity.title,
        imageEntity:
          data.video.entity.image?.entity ||
          data.video.entity.promoImage?.[0]?.entity,
        credit: data.hideVideoCredit
          ? null
          : data.video.entity.video?.entity?.author,
        copy: data.hideVideoDescription
          ? null
          : data.video.entity.promoSummary?.value,
        tag: data.video.entity.taxPrimary?.entity?.name,
        slug: data.video.entity.taxPrimary?.entity?.englishUrl,
        ptt_id: data.video.entity.taxPrimary?.entity?.tid,
        guid: data.video.entity.video?.entity?.guid,
        channel: data.video.entity.channel,
        pgRating: data.video.entity.video?.entity?.pgRating,
        showTitle: data.video.entity.video?.entity?.show?.entity?.title,
        publicationDate: data.video?.entity?.video?.entity?.timestamp
          ? dateService.format(
              data.video?.entity?.video?.entity?.timestamp * 1000,
              'd/m/Y'
            )
          : null,
        noAds: content.noAds,
        schemaData: getVideoSchemaDataFromVideoEntity(data.video?.entity)
      }
    : {
        heading: content.videoRef?.entity?.promoTitle,
        imageEntity: getContentImageEntity(content),
        credit: content.hideVideoCredit
          ? null
          : content.videoRef?.entity?.author,
        copy: null,
        tag: getHubPage(content)?.title || '',
        slug: getPrimaryTaxonomySlug(content),
        ptt_id: content.primaryTaxonomy?.entity?.tid,
        guid: content.videoRef.entity.guid || content.videoRef.entity.mpxGuid,
        channel: content.videoRef?.entity?.show?.[0]?.entity?.channel,
        pgRating: content.videoRef?.entity?.show?.[0]?.entity?.rating,
        showTitle: content.videoRef?.entity?.show?.[0]?.entity?.title,
        publicationDate: dateService.format(
          getContentPublishDate(content) * 1000,
          'd/m/Y'
        ),
        noAds: content.noAds || content.videoRef?.entity?.noAds,
        schemaData: null
      };

  const [inlineVideo, setInlineVideo] = useState(false);
  const playerContainerRef = useRef(null);
  useIntersectionObserver(playerContainerRef, () => setInlineVideo(true));
  const handleOnClick = () => setInlineVideo(true);
  const videoThumb =
    val.imageEntity && val.imageEntity.mediaImage
      ? imageResizerService.getStyleUrl(
          val.imageEntity.mediaImage.url,
          breakpointService.getLargestBreakpoint().width,
          null,
          {
            position: val.imageEntity.focalPoint
          }
        )
      : '';
  const videoPlayerConfig = {
    template: conf.VideoPlayerTemplate,
    autoplay: true,
    id: val.guid,
    video_category: val.tag,
    slug: val.slug ? val.slug.replace('/', '') : null,
    ptt_id: val.ptt_id,
    channel: val.channel,
    pg_rating: val.pgRating,
    showTitle: val.showTitle,
    video_publicationDate: val.publicationDate,
    video_title: val.heading,
    video_thumb: videoThumb
  };
  if (val.noAds) {
    videoPlayerConfig.ads = 'false';
  }
  // Adds alt and title
  if (val.imageEntity?.mediaImage && val.heading) {
    val.imageEntity.mediaImage.alt = val.heading;
    val.imageEntity.mediaImage.title = val.heading;
  }

  const key = `${val.slug}--${val.guid}`;

  return (
    <>
      <div
        ref={playerContainerRef}
        className="ngart-img--video ngart-img--large"
        css={[
          styles.contentVisibility,
          ngartImg,
          ngartImgVideo,
          ngartImgSize.large
        ]}
        key={key}
      >
        <div css={styles.videoWrapper}>
          {inlineVideo && (
            <div css={styles.video}>
              <SdkVideoPlayer config={videoPlayerConfig} content={content} />
            </div>
          )}
          {!isAboveTheFold && (
            <div className="ngart-img__cntr" css={[styles.image, ngartImgCntr]}>
              <Actionable title={t('Play')} onClick={handleOnClick}>
                <Image
                  entity={val.imageEntity}
                  maxWidths={{
                    small_desktop: 789,
                    large_desktop: 760
                  }}
                  aspect="16x9"
                  addSchema
                  loading={isAboveTheFold ? 'eager' : 'lazy'}
                />
                <div
                  className="ngart-img__play-button"
                  css={ngartImgPlayButton}
                />
              </Actionable>
            </div>
          )}
        </div>
        <div className="ngart-img__cont" css={ngartImgCont}>
          {val.heading && (
            <div className="ngart-img__cont__heading">{val.heading}</div>
          )}
          {val.copy && (
            <div className="ngart-img__cont__copy" css={ngartImgContCopy}>
              {stripTags(val.copy)}
            </div>
          )}
          {val.credit && (
            <div
              css={[styles.videoCredit, ngartImgContAuthor]}
              className="ngart-img__cont__author"
            >
              {`${t('by')} `}
              <span
                className="ngart-img__cont--strong"
                css={ngartImgContStrong}
              >
                {val.credit}
              </span>
            </div>
          )}
        </div>
      </div>

      {val.schemaData && (
        <VideoSchema schemaData={val.schemaData} key={`${key}--schema`} />
      )}
    </>
  );
};

ArticleVideoInner.defaultProps = {
  data: undefined,
  content: undefined,
  isParagraph: false,
  isAboveTheFold: false
};

ArticleVideoInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  content: PropTypes.objectOf(PropTypes.any),
  isParagraph: PropTypes.bool,
  isAboveTheFold: PropTypes.bool
};

export const ArticleVideo = ParagraphWrapper(ArticleVideoInner);
